import React from 'react'
import PropTypes from 'prop-types' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { withApollo, useQuery } from 'react-apollo';
import { GET_STATUS, SET_STATUS } from './queries';

export const SuccessMessage = ({ message, onClose, ...rest }) => (
  <div {...rest} className="bg-green-300 text-center text-white text-sm px-4 py-3 relative" role="alert">
    <p>{message}</p>
    <span className="absolute top-0 right-0 text-xl py-2 px-4 cursor-pointer" onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </span>
  </div>
)

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export const ErrorMessage = ({ message, onClose, ...rest }) => (
  <div {...rest} className="bg-red-500 text-center text-white text-sm px-4 py-3 relative" role="alert">
    <p>{message}</p>
    <span className="absolute top-0 right-0 text-xl py-2 px-4 cursor-pointer" onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </span>
  </div>
)

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const Messages = ({ client }) => {

  const { data } = useQuery(GET_STATUS)

  const resetStatus = async () => {
    await client.mutate({
      mutation: SET_STATUS,
      variables: {
        __typename: "Status",
        code: null,
        message: null
      },
      refetchQueries: [{
        query: GET_STATUS
      }]
    })
  }
  
  if (data.status.code === 'ERROR') return <ErrorMessage message={data.status.message} onClose={() => resetStatus()} />
  if (data.status.code === 'SUCCESS') return <SuccessMessage message={data.status.message} onClose={() => resetStatus()} />
  return null
}

Messages.propTypes = {
  status: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
    setStatus: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired
  })
}

export default withApollo(Messages)