import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { H2 } from './Headings'

const Bold = ({ children }) => <span className="font-bold">{children}</span>
const Text = ({ children }) => <p className="text-gray-500 pb-4">{children}</p>
const H3 = ({ children }) => <h3 className="text-2xl font-bold normal-case pt-3 mb-3 text-black">{children}</h3>
const H4 = ({ children }) => <h4 className="text-2xl font-bold normal-case pt-3 mb-3 text-black">{children}</h4>
const H5 = ({ children }) => <h5 className="text-lg font-bold normal-case pt-3 mb-3 text-black">{children}</h5>
const UL = ({ children }) => <ul className="mb-4 list-disc">{children}</ul>
const LI = ({ children }) => <li className="ml-5">{children}</li>
const Img = ({ ...props }) => <img {...props} className="block mb-4" />
const Figure = ({ children }) => <figure className="block my-8">{children}</figure>
const Figcaption = ({ children }) => <figcaption className="text-center italic text-sm">{children}</figcaption>

const defaultOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (_, children) => <H2 icon>{children}</H2>,
    [BLOCKS.HEADING_3]: (_, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (_, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (_, children) => <H5>{children}</H5>,
    [BLOCKS.UL_LIST]: (_, children) => <UL>{children}</UL>,
    [BLOCKS.LIST_ITEM]: (_, children) => <LI>{children}</LI>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data.target && node.data.target.fields) {
        return (
          <Figure>
            <Img src={node.data.target.fields.file["en-US"].url} alt={node.data.target.fields.title["en-US"]} />
            <Figcaption>{node.data.target.fields.title["en-US"]}</Figcaption>
          </Figure>
        )
      }
    }
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
}

const RichText = ({ json, options = {} }) => (
  <>
    {documentToReactComponents(json, {
      ...defaultOptions,
      ...options
    })}
  </>
)

export default RichText