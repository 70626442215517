import React, { useState, useRef, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { push as MobileSlideOutMenu } from 'react-burger-menu'
import Collapsible from 'react-collapsible'
import { useQuery } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';

import Button from './Button'
import CartButton from '../cart/CartButton';
import { GET_CART } from '../cart/queries';
import { useIsMounted } from './customHooks';

const MobileToggleMenuItem = ({children, title}) => (
  <Collapsible 
    trigger={title}
    className="block text-black no-underline uppercase font-bold px-4 py-4 outline-none cursor-pointer"
    openedClassName="block text-black no-underline uppercase font-bold px-4 py-4 outline-none cursor-pointer"
    contentInnerClassName="pl-4 pt-4"
    >
    {children}
  </Collapsible>
)

const MobileMenuIcon = ({ isOpen }) => {
  const { data } = useQuery(GET_CART)
  
  return (
    <div className="relative cursor-pointer">
      <FontAwesomeIcon icon={faBars} size={'2x'} />
      {(!isOpen && data.cart.items.length > 0) && <span className="text-white absolute top-0 right-0 bg-red-600 text-center rounded-full text-xs leading-loose w-6 h-6 -mr-2 -mt-2">{data.cart.items.length}</span> }
    </div>
  )
}

const MobileMenuCloseIcon = () => (
  <div className="cursor-pointer">
    <FontAwesomeIcon icon={faTimes} size={'2x'} />
  </div>
)

const MobileMenu = ({...props}) => {
  const { coachTours, cityTours, cruiseExcursions } = useStaticQuery(
    graphql`
      query MobileMenuQuery	{
        coachTours: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Coach Tours"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        cityTours: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "City Tours"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        cruiseExcursions: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Cruise Excursions"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `  
  )
  const [isOpen, setIsOpen] = useState(false)
  const isMounted = useIsMounted()
  const ref = useRef(null)

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('resize', () => closeMenu());
    }
    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('resize', () => closeMenu());
      }
    };
  });

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen)
  }

  const closeMenu = () => {
    if (isMounted.current) {
      setIsOpen(false)
    }
  }
    
  return (
    <MobileSlideOutMenu isOpen={isOpen} customBurgerIcon={<MobileMenuIcon isOpen={isOpen} />} customCrossIcon={<MobileMenuCloseIcon />}  {...props} className={`sm:hidden bg-white w-10 p-6 ${isOpen && 'menu-open'}`} onStateChange={(state) => handleStateChange(state)} right ref={ref}>
      <Link
        to="/"
        className="block text-black no-underline px-4 py-4 outline-none cursor-pointer uppercase font-bold"
      >
        Home
      </Link>
      <MobileToggleMenuItem title="Causeway Tours">
        {coachTours.edges.map(({node}, index) => (
        <Link
          to={`/coach-tours/${node.slug}`}
          className={`text-sm leading-normal text-gray-400 block no-underline ${(index + 1 === coachTours.edges.length) ? 'pb-0' : 'pb-4'}`}
          key={index}
        >
          {node.title}
        </Link>
        ))}
      </MobileToggleMenuItem>
      <MobileToggleMenuItem title="City Tours">
        {cityTours.edges.map(({node}, index) => (
        <Link
          to={`/coach-tours/${node.slug}`}
          className={`text-sm leading-normal text-gray-400 block no-underline ${(index + 1 === cityTours.edges.length) ? 'pb-0' : 'pb-3'}`}
          key={index}
        >
          {node.title}
        </Link>
        ))}
      </MobileToggleMenuItem>
      <MobileToggleMenuItem title="Cruise Excursions">
        {cruiseExcursions.edges.map(({node}, index) => (
        <Link
          to={`/coach-tours/${node.slug}`}
          className={`text-sm leading-normal text-gray-400 block no-underline ${(index + 1 === cruiseExcursions.edges.length) ? 'pb-0' : 'pb-3'}`}
          key={index}
        >
          {node.title}
        </Link>
        ))}
      </MobileToggleMenuItem>
      <Link
        to="/private-bespoke-coach-tours"
        className="block text-black no-underline uppercase font-bold px-4 py-4 outline-none"
      >
        Private Tours
      </Link>
      <CartButton className="px-4 mx-4 my-2 block text-center">Cart</CartButton>
      <Button to="tel:+442895739113" className="btn btn-green px-4 mx-4 my-2 text-center"><FontAwesomeIcon icon={faPhone} className="mr-1" /> Call Us</Button>
    </MobileSlideOutMenu>
  )
}

export default MobileMenu