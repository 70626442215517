import { Link } from 'gatsby'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import Button from './Button'
import CartButton from '../cart/CartButton'
import MenuBar from './MenuBar';
import Messages from './Messages';

import Logo from '../../images/logotoursireland.svg'

const TopBar = ({ siteTitle, className }) => {
  return (
    <div className={className}>
      <div className="container mx-auto px-4 py-3 relative shadow sm:shadow-none">
        <h1 className="flex justify-center">
          <Link
            to="/"
            className="block h-12 md:h-20"
          >
            <img src={Logo} alt={siteTitle} className="block h-full" />
          </Link>
        </h1>
        <div className="hidden absolute top-0 right-0 h-full mr-4 sm:flex items-center">
          <CartButton />
          <Button to="tel:+442895739113" className="btn btn-green px-4"><FontAwesomeIcon icon={faPhone} className="mr-2" /> Call Us</Button>
        </div>
      </div>
      <MenuBar />
      <div id="messages">
        <Messages />
      </div>
    </div>
  )
}

export default TopBar
