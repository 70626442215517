import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import TopBar from './global/TopBar'
import Footer from './global/Footer'
import MobileMenu from './global/MobileMenu'

const Layout = ({ children }) => {
  const { contentfulSite } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        contentfulSite {
          title
          heroSlider {
            title
            backgroundImage {
              fixed(width: 1600, quality: 100) {
                src
              }
            }
            content {
              json
            }
            link
            linkText
          }
        }
      }
    `
  )

  return (
    <>
      <div id="outer-container">
        <MobileMenu pageWrapId="page-wrap" outerContainerId="outer-container" />
        <div id="page-wrap">
          <header className="relative z-10">
            <TopBar className="bg-opaque-white-90" siteTitle={contentfulSite.title} />
          </header>
          <main>
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
