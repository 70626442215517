import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Popup from 'reactjs-popup'

const DropdownMenu = ({ title, tours }) => (
  <Popup
    trigger={open => (
      <div className={`block no-underline text-sm md:text-base px-4 md:px-4 lg:px-6 py-4 border-r border-gray-100 ${open ? 'bg-gray-500 text-white' : 'text-black'}`}>{title}</div>
    )}
    position="bottom left"
    on="hover"
    closeOnDocumentClick
    mouseLeaveDelay={100}
    mouseEnterDelay={0}
    contentStyle={{ padding: '0px', border: 'none', width: 250 }}
    arrow={false}
  >
    <div className="bg-gray-500 p-8">
      {tours.map(({node}, index) => (
        <Link
          to={`/coach-tours/${node.slug}`}
          className={`text-sm leading-normal uppercase block text-white no-underline ${(index + 1 === tours.length) ? 'pb-0' : 'pb-3'}`}
          key={index}
        >
          {node.title}
        </Link>
      ))}
    </div>
  </Popup>
)

const MenuBar = () => {
  const { coachTours, cityTours, cruiseExcursions } = useStaticQuery(
    graphql`
      query MenuQuery	{
        coachTours: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Coach Tours"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        cityTours: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "City Tours"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        cruiseExcursions: allContentfulTour(sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Cruise Excursions"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )
  return (
    <div className="hidden sm:flex items-center justify-center flex-wrap text-md px-4 bg-opaque-white-90 shadow">
      <Link
        to="/"
        className="block text-black no-underline text-sm md:text-base px-4 md:px-4 lg:px-6 py-4 border-r border-gray-100"
      >
        Home
      </Link>
      <DropdownMenu title="Coach Tours" tours={coachTours.edges} />
      <DropdownMenu title="City Tours" tours={cityTours.edges} />
      <DropdownMenu title="Cruise Excursions" tours={cruiseExcursions.edges} />
      <Link
        to="/private-bespoke-coach-tours"
        className="block text-black no-underline py-4 text-sm md:text-base px-4 md:px-4 lg:px-6"
      >
        Private Tours
      </Link>
    </div>
  )
}

export default MenuBar