import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faTripadvisor, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Logo from '../../images/logotoursireland-white.svg'

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query FooterMenuQuery	{
        toursFromBelfast: allContentfulTour(limit: 6, sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Tours from Belfast"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        coachToursFromBelfast: allContentfulTour(limit: 6, sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Coach Tours from Belfast"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
        dayTrips: allContentfulTour(limit: 6, sort: {
          fields: [sortOrder],
          order: ASC
        }, filter: {
          menuCategory: {
            eq: "Day Trips from Belfast"
          }
        }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )

  return (
    <footer>
      <div className="bg-gray-500">
        <div className="text-center md:text-left container mx-auto px-4 py-20 flex flex-col-reverse md:flex-row md:flex-wrap text-white text-base">
          <div className="md:w-1/2 lg:w-1/4 md:mb-16 lg:mb-0 px-4">
            <img src={Logo} className="lg:-mt-8 mb-6 mx-auto md:ml-0" alt="Tours Ireland Logo" />
            <ul className="mt-2 text-sm font-normal mb-6">
              <li className="py-1">Tel: <a href="tel:+442895739113" title="Phone Tours Ireland">(028) 95 739 113</a></li>
              <li className="py-1 mb-4">Email: <a href="mailto:hello@toursireland.co.uk" title="Email Tours Ireland">hello@toursireland.co.uk</a></li>
              <li className="py-1"><Link to="/conditions-of-hire">Conditions of Hire</Link></li>
              <li className="py-1"><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
            <p className="text-white text-sm font-normal mb-6">Copyright &copy; 2019 Tours Ireland.<br/>All Rights Reserved.</p>
            <p className="text-sm"><a href="https://www.amigostudios.co" title="Amigo Studios Web Design &amp; Development">Web Design by amigo.</a></p>
          </div>
          <div className="md:w-1/2 lg:w-1/4 mb-16 lg:mb-0 px-4">
            <h4 className="uppercase">Tours From Belfast</h4>
            <ul className="mt-2 text-sm font-normal">
              {data.toursFromBelfast && data.toursFromBelfast.edges.map(({node}, index) => (
                <li className="py-3" key={index}><Link to={`/coach-tours/${node.slug}`}>{node.title}</Link></li>
              ))}
            </ul>
          </div>
          <div className="md:w-1/2 lg:w-1/4 px-4">
            <h4 className="uppercase">Tours From Belfast</h4>
            <ul className="mt-2 text-sm mb-16 md:mb-0 font-normal">
              {data.coachToursFromBelfast && data.coachToursFromBelfast.edges.map(({node}, index) => (
                <li className="py-3" key={index}><Link to={`/coach-tours/${node.slug}`}>{node.title}</Link></li>
              ))}
            </ul>
          </div>
          <div className="md:w-1/2 lg:w-1/4 px-4">
            <h4 className="uppercase">Tours Around Ireland</h4>
            <ul className="mt-2 text-sm mb-16 md:mb-0 font-normal">
              {data.dayTrips && data.dayTrips.edges.map(({node}, index) => (
                <li className="py-3" key={index}><Link to={`/coach-tours/${node.slug}`}>{node.title}</Link></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-gray-700">
        <div className="container mx-auto px-4 py-4 text-center">
          <a href="https://www.facebook.com/coachtoursireland/" title="Tours Ireland Facebook" className="inline-block px-2 text-white"><FontAwesomeIcon icon={faFacebookF} /></a>
          <a href="https://twitter.com/toursireland3" title="Tours Ireland Twitter" className="inline-block px-2 text-white"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="https://www.instagram.com/coachtoursireland/" title="Tours Ireland Instagram" className="inline-block px-2 text-white"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="https://www.tripadvisor.co.uk/Attraction_Review-g186470-d15759631-Reviews-Tours_Ireland-Belfast_Northern_Ireland.html" title="Tours Ireland TripAdvisor" className="inline-block px-2 text-white"><FontAwesomeIcon icon={faTripadvisor} /></a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
