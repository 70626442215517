import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useQuery } from 'react-apollo'

import Button from '../global/Button'

import { GET_CART } from './queries'

const CartButton = ({ className, children }) => {
  const { data } = useQuery(GET_CART)

  return (
    <Button to="/cart" className={`btn btn-gray sm:mr-2 px-4 relative ${className}`}>
      <FontAwesomeIcon icon={faShoppingCart} className="sm:mr-0 mr-2" />
      {children}
      {(data.cart && data.cart.items && data.cart.items.length > 0) && <span className="absolute sm:bottom-0 top-0 sm:top-auto right-0 bg-red-600 text-center rounded-full text-xs leading-loose w-6 h-6 -mr-2 -mt-2 sm:-mr-1 sm:-mb-1">{data.cart.items.length}</span> }
    </Button>
  )
}

export default CartButton